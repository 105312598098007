<script>
import VueContentLoading from 'vue-content-loading';

export default {
  components: {
    VueContentLoading,
  },
};
</script>

<template>
  <div>
    <vue-content-loading :width="300" :height="100">
      <rect x="5" y="10" width="90" height="135" />
      <rect x="110" y="20" width="130" height="10" />
      <rect x="110" y="40" width="110" height="10" />
      <rect x="110" y="52" width="60" height="10" />
      <rect x="110" y="70 " width="80" height="20" />
    </vue-content-loading>
  </div>
</template>